import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import MainContentWrap from "../Wrappers/MainContentWrap"
import webboxesBanner from "../../images/illustrations/art_2.jpg"
import styled from "styled-components"

const AboutWeAreWrap = styled.div`
  height: 1100px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.41),
      rgba(255, 255, 255, 0.2)
    ),
    url(${webboxesBanner});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export default function AboutWeAre() {
  const { t } = useTranslation()
  return (
    <AboutWeAreWrap>
      <MainContentWrap>
        <div id="about" style={{ marginTop: "15%" }}>
          <div className="container-big">
            <div className="para-about fixed-back">
              <h1 className="fadeup">{t("title")}</h1>
              <div className="fadeup fadeup-2 sub">
                {t("description1")}
                <p>{t("description2")}</p>
              </div>
            </div>
          </div>
        </div>

        <div id="about-two" style={{ marginTop: "15%" }}>
          <div className="container-big fixed-section">
            <div className="para-about2">
              <h2 className="fadeup">{t("title2")}</h2>
              <div className="fadeup fadeup-2 sub">{t("description3")}</div>
            </div>
          </div>
        </div>
      </MainContentWrap>
    </AboutWeAreWrap>
  )
}
