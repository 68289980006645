import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"

const IndustriesSectionWrap = styled.div`
  width: 100%;
  .row {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .about-item {
    width: 40%;
  }

  @media (min-width: 400px) {
    .about-item {
      width: 30%;
    }
  }

  @media (min-width: 1024px) {
    .about-item {
      width: 15%;
    }
  }
`

export default function IndustriesSection() {
  const { t } = useTranslation()
  return (
    <IndustriesSectionWrap>
      <div className="row fadeup">
        <div className="about-item">
          <div className="listing">
            <div className="title">{t("consumer")}</div>
            <ul className="body list-unstyled">
              <li>Anlene</li>
              <li>Anmum</li>
              <li>Downy</li>
              <li>Fitbar</li>
              <li>Futami</li>
              <li>Green Leaf</li>
              <li>P&amp;G</li>
              <li>Pampers</li>
              <li>Pantene</li>
              <li>RJ London</li>
              <li>Whiskas</li>
            </ul>
          </div>
        </div>
        <div className="about-item">
          <div className="listing">
            <div className="title">{t("financial")}</div>
            <ul className="body list-unstyled">
              <li>BCA</li>
              <li>Mandiri</li>
              <li>MNC Bank</li>
              <li>VISA</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">{t("retail")}</div>
            <ul className="body list-unstyled">
              <li>Ranch Market</li>
              <li>Zippo</li>
              <li>Farmers Market</li>
              <li>The Grand</li>
              <li>eStore</li>
              <li>Boogybaby</li>
              <li>Chocochips</li>
              <li>Her Spot</li>
            </ul>
          </div>
        </div>

        <div className="about-item">
          <div className="listing">
            <div className="title">
              {t("travel")} &amp; {t("tourism")}
            </div>
            <ul className="body list-unstyled">
              <li>Ayana</li>
              <li>Delonix</li>
              <li>Quest Lagoon</li>
              <li>Rimba</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">
              {t("food")} &amp; {t("beverage")}
            </div>
            <ul className="body list-unstyled">
              <li>Berrykitchen</li>
              <li>Coldpress</li>
              <li>Dapur Cokelat</li>
              <li>Laus Kopi</li>
              <li>Sekar Bumi</li>
              <li>Slim Gourmet</li>
              <li>Urban Remedy</li>
            </ul>
          </div>
        </div>

        <div className="about-item">
          <div className="listing">
            <div className="title">{t("realEstate")}</div>
            <ul className="body list-unstyled">
              <li>Moscow CTGR</li>
              <li>South Estates</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">{t("manufacturer")}</div>
            <ul className="body list-unstyled">
              <li>Dekkson</li>
              <li>Dover Chemical</li>
              <li>Karya Tekhnik Utama</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">{t("service")}</div>
            <ul className="body list-unstyled">
              <li>Ideanesia</li>
              <li>Suryamas Logistik</li>
              <li>Xprins</li>
            </ul>
          </div>
        </div>

        <div className="about-item">
          <div className="listing">
            <div className="title">{t("government")}</div>
            <ul className="body list-unstyled">
              <li>DPR RI</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">{t("energy")}</div>
            <ul className="body list-unstyled">
              <li>XADO Group</li>
              <li>OMV</li>
            </ul>
          </div>
          <div className="listing">
            <div className="title">{t("nonProfit")}</div>
            <ul className="body list-unstyled">
              <li>Beginchange</li>
              <li>Berlari Untuk Berbagi</li>
            </ul>
          </div>
        </div>
      </div>
    </IndustriesSectionWrap>
  )
}
