import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

import MainContentWrap from "../Wrappers/MainContentWrap"
import values from "../../utils/values"

import arrow from "../../images/icons/utils/arrow.svg"
import alexFoto from "../../images/wbx-team-foto/alex/alex-chat.jpg"
import valeriiaFoto from "../../images/wbx-team-foto/valery/valeriia.png"
import hoFoto from "../../images/wbx-team-foto/ho/ho.png"
import valentinFoto from "../../images/wbx-team-foto/valentin/valentinanastasev.png"
import zoyaFoto from "../../images/wbx-team-foto/zoya/zoyapaliashchuk_marketing_pr.png"
import dmytroFoto from "../../images/wbx-team-foto/dmytro/dmytro.png"
import hermanFoto from "../../images/wbx-team-foto/herman/herman-s.png"
import nomanFoto from "../../images/wbx-team-foto/noman/noman-s.png"
import vladFoto from "../../images/wbx-team-foto/vlad/vlad-s.png"
import eddyFoto from "../../images/wbx-team-foto/eddy/eddy.png"
import juliaFoto from "../../images/wbx-team-foto/julia/julia-s.png"
import rifatFoto from "../../images/wbx-team-foto/rifat/rifat.png"

const AboutTeamWrap = styled.div`
  .team-title {
    margin-bottom: 10rem;
  }

  .founder {
    margin-bottom: 150px;
  }

  .team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .team-list-item {
    margin-bottom: 100px;
  }

  .team-member-title {
    margin-bottom: 0.75rem;
  }

  @media (min-width: ${values.DEVICESIZE.tablet}) {
    .team-list {
      justify-content: space-between;
    }
  }
  @media (min-width: ${values.DEVICESIZE.laptopL}) {
    max-width: 1250px;
    .team-list-item:not(:nth-child(3n)) {
      margin-right: 100px;
    }
  }
  span {
    display: block;
  }

  img {
    width: 250px;
    border-radius: 250px;
    margin-bottom: 1.5rem;
    border: 1px solid lightgrey;
  }
`

export default function AboutTeam() {
  const { t } = useTranslation()
  return (
    <MainContentWrap>
      <AboutTeamWrap>
        <h2 className="fadeup team-title">{t("teamSecTitle")}</h2>

        <div className="row">
          <div className="founder">
            <img src={alexFoto} alt="WebBoxes team" />
            <h3 className="team-member-title">Alex</h3>
            <span>{t("founder")}</span>
          </div>
          <div className="team-list">
            <div className="team-list-item">
              <img src={juliaFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Julia</h3>
              <span>{t("finances")}</span>
            </div>
            <div className="team-list-item">
              <img src={zoyaFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Zoya</h3>
              <span>{t("zjuniorwebdev")}</span>
            </div>
            <div className="team-list-item">
              <img src={valentinFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Valentin</h3>
              <span>{t("sales")}</span>
            </div>
            <div className="team-list-item">
              <img src={dmytroFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Dmytro</h3>
              <span>{t("dmytrodesc1")}</span>
              <span>{t("dmytrodesc2")}</span>
            </div>
            <div className="team-list-item">
              <img src={nomanFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Noman</h3>
              <span>{t("machineLearning")}</span>
            </div>
            <div className="team-list-item">
              <img src={hoFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Ho</h3>
              <span>{t("softwareEngineer")}</span>
            </div>
            <div className="team-list-item">
              <img src={valeriiaFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Valery</h3>
              <span>{t("softwareEngineer")}</span>
            </div>
            <div className="team-list-item">
              <img src={eddyFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Eddy</h3>
              <span>{t("softwareEngineer")}</span>
            </div>

            <div className="team-list-item">
              <img src={rifatFoto} alt="WebBoxes team" />
              <h3 className="team-member-title">Rifat</h3>
              <span>{t("softwareEngineer")}</span>
            </div>
          </div>
        </div>
      </AboutTeamWrap>
      <Link to="/service/" className="arrowlink fadeup fadeup-2">
        {t("servicesLink")}
        <img src={arrow} alt="arrow" />
      </Link>
    </MainContentWrap>
  )
}
