import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import SEO from "../components/seo"
import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"
import AboutWeAre from "../components/About/AboutWeAre"
import AboutTeam from "../components/About/AboutTeam"
import AboutClients from "../components/About/AboutClients"

const About = () => {
  const { t } = useTranslation()
  return (
    <>
      <SEO title={t("seoTitle")} />
      <ColorThemeWrapper
        wrap1={<AboutWeAre />}
        wrap2={<AboutTeam />}
        wrap3={<AboutClients />}
      />
    </>
  )
}

export default About

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
