import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

import MainContentWrap from "../Wrappers/MainContentWrap"
import ClientsLogoBoard from "../SingleComponents/ClientsLogoBoard"
import IndustriesSection from "../SingleComponents/IndustriesSection"

const AboutClientsWrap = styled.div`
  width: 100%;
  margin-top: 15rem;
  .white-line {
    background: white;
  }

  .aboutPartners-title {
    margin-bottom: 5rem;
  }
`

export default function AboutClients() {
  const { t } = useTranslation()
  return (
    <MainContentWrap>
      <AboutClientsWrap>
        <div className="aboutPartners-title wrapper-center">
          <h2 className="fadeup">{t("clientsSecTitle")}</h2>
          <div className="fadeup fadeup-2 sub">
            {t("clientsSecDescription")}
          </div>
        </div>

        <hr className="white-line" />
        <ClientsLogoBoard />
        <hr className="white-line" />
        <IndustriesSection />
      </AboutClientsWrap>
    </MainContentWrap>
  )
}
